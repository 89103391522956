var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AuthCard', [_c('Form', {
    ref: "resetPasswordForm",
    staticClass: "classic-form",
    attrs: {
      "model": _vm.resetPasswordForm,
      "rules": _vm.resetPasswordRules
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "Email",
      "prop": "email"
    }
  }, [_c('Input', {
    attrs: {
      "type": "email",
      "icon": "ios-mail-outline",
      "placeholder": "E-mail"
    },
    model: {
      value: _vm.resetPasswordForm.email,
      callback: function callback($$v) {
        _vm.$set(_vm.resetPasswordForm, "email", $$v);
      },
      expression: "resetPasswordForm.email"
    }
  })], 1), _c('FormItem', {
    staticClass: "form-action-btn"
  }, [_c('Button', {
    staticClass: "btn--elevated",
    attrs: {
      "loading": _vm.isLoading,
      "type": "primary",
      "size": "large",
      "long": ""
    },
    on: {
      "click": function click($event) {
        return _vm.askPasswordReset();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.user.reset_pass')) + " ")])], 1), _c('div', {
    staticClass: "alternate-btn"
  }, [_c('router-link', {
    staticClass: "clickable-el heavy",
    attrs: {
      "tag": "a",
      "to": "/auth/login"
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-left"
  }), _vm._v(" " + _vm._s(_vm.$t('global.user.back_login')) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }