<template>
  <AuthCard>
    <Form
      class="classic-form"
      ref="resetPasswordForm"
      :model="resetPasswordForm"
      :rules="resetPasswordRules"
    >
      <FormItem
        label="Email"
        prop="email"
      >
        <Input
          type="email"
          v-model="resetPasswordForm.email"
          icon="ios-mail-outline"
          placeholder="E-mail"
        />
      </FormItem>
      <FormItem class="form-action-btn">
        <Button
          :loading="isLoading"
          type="primary"
          class="btn--elevated"
          size="large"
          long
          @click="askPasswordReset()"
        >
          {{ $t('global.user.reset_pass') }}
        </Button>
      </FormItem>
      <div class="alternate-btn">
        <router-link
          tag="a"
          to="/auth/login"
          class="clickable-el heavy"
        >
          <i class="fa fa-arrow-left" />
          {{ $t('global.user.back_login') }}
        </router-link>
      </div>
    </Form>
  </AuthCard>
</template>

<script>
import AuthCard from '@/views/Login/AuthCard'

export default {
  name: 'ForgotPassword',
  components: {
    AuthCard
  },
  data () {
    return {
      isLoading: false,
      resetPasswordForm: {
        email: ''
      },
      resetPasswordRules: {
        email: [
          {required: true, trigger: 'blur'},
          {type: 'email', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    async askPasswordReset () {
      await this.$refs.resetPasswordForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            await this.$axios.get('users/forgotPassword', {
              params: {email: this.resetPasswordForm.email}
            })
            this.isLoading = false
            this.$Notice.open({
              title: this.$t('global.user.watch_box'),
              desc: this.$t('global.user.box_success')
            })
          } catch (e) {
            this.isLoading = false
            console.error(e)
            this.$Notice.open({
              title: this.$t('global.validation.error'),
              desc: this.$t('global.validation.serv_error')
            })
            
          }
        } else {
          this.isLoading = false
        }
      })
      this.$refs.resetPasswordForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
    .classic-form {
        .ivu-form-item {
            margin-top: 20px;

            &.form-action-btn {
                margin-top: 30px;
            }
        }
    }

    .alternate-btn {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>
